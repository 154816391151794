.header {
    background: var(--cprimary);
    border-bottom: var(--sidebar-border);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
}

.logo {
    font-size: 1.5em;
    height: 1em;
}

.logo:before {
    background: url('../../assets/img/logo.png') no-repeat 50% 50%;
    content: '';
    display: block;
    filter: invert(100%) var(--white-color-transform);
    float: left;
    height: 50px;
    margin-right: 10px;
    margin-top: -5px;
    width: 50px;
}

@media (hover: none) {
    [class*="acDEekrstu"] .logo:before {
        background-image: url("../../assets/img/test-min.png");
        filter: none;
    }
}
@media screen and (max-width: 519px) {
    .container {
        justify-content: center;
    }
    .label {
        position: absolute;
        right: 15px;
        top: calc(var(--header-height) + 20px);
        text-shadow: 0 0 2px #fff;
    }
}
@media screen and (max-width: 379px) {
    .container {
        width: 100% !important;
    }
    .logo {
        transform: scale(0.8);
        white-space: nowrap;
    }
}
