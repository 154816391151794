.game {
    position: relative;
}
.ui {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.pano {
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100vh;
}
.minimap {
    width: 100%;
    height: 100%;
}
.place_point,
.place_point * {
    cursor: crosshair;
}
