.results {
    padding: 40px 0;
}
.map {
    width: 70%;
    height: 500px;
    margin: 0 auto;
}
.main {
    background-color:rgba(255,255,255,.05);
    border-radius:10px;
    margin: 40px;
    padding: 40px;
    font-size: 40px;
}

.row {
    display: flex;
}
.row > * {
    flex: 1;
}
.col {
    padding: 10px;
}
.col:first-child {
    text-align: right;
}
.col:last-child {
    text-align: left;
}
.col:only-child {
    text-align: center;
}
.val {
    font-family: monospace;
    font-size: 0.9em;
}

.home_link {
    font-size: 35px;
    text-align: center;
}

@media screen and (max-width: 1219px) {
    .main {
        font-size: 30px;
    }
}
@media screen and (max-width: 799px) {
    .row {
        display: block;
    }
    .col {
        text-align: center !important;
        word-break: break-word;
    }
    .show_formulas {
        display: none;
    }
}
