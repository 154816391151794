.enter_game {
    width: 300px;
    margin: 0 auto;
}

@media screen and (max-width: 479px) {
    .enter_game {
        width: 100%;
    }
}
